import request from 'utils/request';

const api = {
  // top offers & GEOs
  REPORT_LIST: '/api/affiliate/overall/{affiliateId}/reportList',
  // account access aff
  ACCOUNT_LIST: '/api/affiliate/overall/{affiliateId}/account/list',
  ADD_ACCOUNT: '/api/affiliate/overall/{affiliateId}/account/add',
  UPDATE_ACCOUNT: '/api/affiliate/overall/{affiliateId}/account/update',
  DELTEE_ACCOUNT: '/api/affiliate/overall/{affiliateId}/account/delete',

  // source whitelist
  SOURCE_WHITE_LIST: '/api/affiliate/overall/{affiliateId}/sourceWhite/list',
  ADD_SOURCE_WHITE_LIST: '/api/affiliate/overall/{affiliateId}/sourceWhite/add',
  DELETE_SOURCE_WHITE_LIST: '/api/affiliate/overall/{affiliateId}/sourceWhite/delete',

  // offer whiteist
  OFFER_WHITE_LIST: '/api/affiliate/overall/{affiliateId}/offerWhite/list',
  ADD_OFFER_WHITE_LIST: '/api/affiliate/overall/{affiliateId}/offerWhite/add',
  DELETE_OFFER_WHITE_LIST: '/api/affiliate/overall/offerWhite/deleteById',
  DELETE_ALL_OFFER_WHITE_LIST: '/api/affiliate/overall/{affiliateId}/offerWhite/deleteAll',
  EXPORT_OFFER_WHITE_LIST: '/api/affiliate/overall/{affiliateId}/offerWhite/export',
  UPDATE_PAYOUT_OFFER_WHITE_LIST: '/api/affiliate/overall/{affiliateId}/offerWhite/updatePayout',

  // source sub whitelist
  SOURCE_SUB_WHITE_LIST: '/api/affiliate/overall/{affiliateId}/sourceSubWhite/list',
  ADD_SOURCE_SUB_WHITE_LIST: '/api/affiliate/overall/{affiliateId}/sourceSubWhite/add',
  DELETE_SOURCE_SUB_WHITE_LIST: '/api/affiliate/overall/{affiliateId}/sourceSubWhite/delete',

  // offer aff sub cap setting
  OFFER_SUB_CAP_LIST: '/api/affiliate/overall/{affiliateId}/offerSubCap/list',
  ADD_OFFER_SUB_CAP: '/api/affiliate/overall/{affiliateId}/offerSubCap/add',
  UPDATE_OFFER_SUB_CAP: '/api/affiliate/overall/{affiliateId}/offerSubCap/update',
  DELETE_OFFER_SUB_CAP: '/api/affiliate/overall/{affiliateId}/offerSubCap/delete',

  // tags whitelist
  TAGS_WHITELIST: '/api/affiliate/overall/{affiliateId}/tags/list',
  ADD_TAGS_WHITELIST: '/api/affiliate/overall/{affiliateId}/tags/add',
  DELETE_TAGS_WHITELIST: '/api/affiliate/overall/{affiliateId}/tags/delete',
  TAGS_LIST: '/api/tags/list',
  CHECK_COUNT: '/api/affiliate/overall/{affiliateId}/tags/checkCount',

  // payout setting
  AFF_OFFER_PAYOUT_LIST: '/api/affiliate/overall/{affiliateId}/offer/payout/list',
  ADD_AFF_OFFER_PAYOUT: '/api/affiliate/overall/{affiliateId}/offer/payout/add',
  UPDATE_AFF_OFFER_PAYOUT: '/api/affiliate/overall/{affiliateId}/offer/payout/update',
  DELETE_AFF_OFFER_PAYOUT: '/api/affiliate/overall/{affiliateId}/offer/payout/delete',
  AFF_SOURCE_PAYOUT_LIST: '/api/affiliate/overall/{affiliateId}/source/payout/list',
  ADD_AFF_SOURCE_PAYOUT: '/api/affiliate/overall/{affiliateId}/source/payout/add',
  UPDATE_AFF_SOURCE_PAYOUT: '/api/affiliate/overall/{affiliateId}/source/payout/update',
  DELETE_AFF_SOURCE_PAYOUT: '/api/affiliate/overall/{affiliateId}/source/payout/delete',
  AFF_PAYOUT_LIST: '/api/affiliate/overall/{affiliateId}/payout',
  ADD_AFF_PAYOUT: '/api/affiliate/overall/{affiliateId}/payout/add',
  UPDATE_AFF_PAYOUT: '/api/affiliate/overall/{affiliateId}/payout/update',
  DELETE_AFF_PAYOUT: '/api/affiliate/overall/{affiliateId}/payout/delete',

  //adjust rate setting
  ADJUST_RATE_LIST: '/api/affiliate/overall/{affiliateId}/adjust/list',
  ADD_ADJUST_RATE: '/api/affiliate/overall/{affiliateId}/adjust/add',
  DELETE_ADJUST_RATE: '/api/affiliate/overall/{affiliateId}/adjust/delete',

  // source blacklist
  SOURCE_BLACKLIST: '/api/affiliate/overall/{affiliateId}/sourceBlack/list',
  ADD_SOURCE_BLACKLIST: '/api/affiliate/overall/{affiliateId}/sourceBlack/add',
  DELETE_SOURCE_BLACKLIST: '/api/affiliate/overall/{affiliateId}/sourceBlack/delete',

  // prod blacklist
  PROD_BLACKLIST: '/api/affiliate/overall/{affiliateId}/prodBlack/list',
  ADD_PROD_BLACKLIST: '/api/affiliate/overall/{affiliateId}/prodBlack/add',
  DELETE_PROD_BLACKLIST: '/api/affiliate/overall/{affiliateId}/prodBlack/delete',

  // offer black
  OFFER_BLACKLIST: '/api/affiliate/overall/{affiliateId}/offerBlack/list',
  ADD_OFFER_BLACKLIST: '/api/affiliate/overall/{affiliateId}/offerBlack/add',
  DELETE_OFFER_BLACKLIST: '/api/affiliate/overall/{affiliateId}/offerBlack/delete',

  // offer aff sub blacklist
  OFFER_SUB_BLACKLIST: '/api/affiliate/overall/{affiliateId}/offerSubBlack/list',
  ADD_OFFER_SUB_BLACKLIST: '/api/affiliate/overall/{affiliateId}/offerSubBlack/add',
  DELETE_OFFER_SUB_BLACKLIST: '/api/affiliate/overall/{affiliateId}/offerSubBlack/delete',

  // source sub blacklist
  SOURCE_SUB_BLACKLIST: '/api/affiliate/overall/{affiliateId}/sourceSubBlack/list',
  ADD_SOURCE_SUB_BLACKLIST: '/api/affiliate/overall/{affiliateId}/sourceSubBlack/add',
  DELETE_SOURCE_SUB_BLACKLIST: '/api/affiliate/overall/{affiliateId}/sourceSubBlack/delete',
  // Having Offer
  AFF_OFFER_DISTRIBUTION: '/api/affiliate/overall/{affiliateId}/getOffers',

  // FLOW TAG
  FLOW_TAG_LIST: '/api/affiliate/overall/traffic/list',
  ADD_FLOW_TAG: '/api/affiliate/overall/traffic/add',
  DELETE_FLOW_TAG: '/api/affiliate/overall/traffic/delete',
  CONFIRM_TRAFFIC: '/api/affiliate/overall/traffic/confirmTraffic',
};

// top offers & GEOs
export function reloadTopOfferGeos(affiliateId, period) {
  const url = api.REPORT_LIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'get',
    params: {
      period,
    },
  });
}
// account access aff
export function getAccountList(affiliateId, id) {
  const url = api.ACCOUNT_LIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'get',
    params: {
      id,
    },
  });
}
export function addAccount(affiliateId, data) {
  const url = api.ADD_ACCOUNT.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'post',
    data,
  });
}
export function updateAccount(affiliateId, data) {
  const url = api.UPDATE_ACCOUNT.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'put',
    data,
  });
}
export function deleteAccountList(affiliateId, id) {
  const url = api.DELTEE_ACCOUNT.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'delete',
    params: {
      id,
    },
  });
}

// source white list
export function getSourceWhiteList(affiliateId, query) {
  const url = api.SOURCE_WHITE_LIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'get',
    params: query,
  });
}
export function addSourceWhiteList(affiliateId, data) {
  const url = api.ADD_SOURCE_WHITE_LIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'post',
    data,
  });
}
export function deletSourceWhiteList(affiliateId, sourceIds) {
  const url = api.DELETE_SOURCE_WHITE_LIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'delete',
    params: {
      sourceIds,
    },
  });
}

// offer white list
export function getOfferWhiteList(affiliateId, query) {
  const url = api.OFFER_WHITE_LIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'get',
    params: query,
  });
}
export function addOfferWhiteList(affiliateId, data) {
  const url = api.ADD_OFFER_WHITE_LIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'post',
    data,
  });
}

export function updatePayout(affiliateId, data) {
  const url = api.UPDATE_PAYOUT_OFFER_WHITE_LIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'put',
    data,
  });
}
export function deleteOfferWhiteList(ids) {
  const url = api.DELETE_OFFER_WHITE_LIST;
  return request({
    url,
    method: 'delete',
    params: {
      ids,
    },
  });
}
export function deleteAllOfferWhiteList(affiliateId) {
  const url = api.DELETE_ALL_OFFER_WHITE_LIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'delete',
  });
}

// source sub whitelist
export function getSourceSubWhiteList(affiliateId, query) {
  const url = api.SOURCE_SUB_WHITE_LIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'get',
    params: query,
  });
}
export function addSourceSubWhiteList(affiliateId, data) {
  const url = api.ADD_SOURCE_SUB_WHITE_LIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'post',
    data,
  });
}
export function deleteSourceSubWhiteList(affiliateId, ids) {
  const url = api.DELETE_SOURCE_SUB_WHITE_LIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'delete',
    params: {
      ids,
    },
  });
}

// offer aff sub cap setting
export function getOfferSubCapList(affiliateId, query) {
  const url = api.OFFER_SUB_CAP_LIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'get',
    params: query,
  });
}
export function addOfferSubCap(affiliateId, data) {
  const url = api.ADD_OFFER_SUB_CAP.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'post',
    data,
  });
}
export function updateOfferSubCap(affiliateId, data) {
  const url = api.UPDATE_OFFER_SUB_CAP.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'put',
    data,
  });
}
export function deleteOfferSubCap(affiliateId, ids) {
  const url = api.DELETE_OFFER_SUB_CAP.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'delete',
    params: {
      ids,
    },
  });
}

// tags whitelist
export function getTagsWhitelist(affiliateId, query) {
  const url = api.TAGS_WHITELIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'get',
    params: query,
  });
}
export function addTagsWhitelist(affiliateId, data) {
  const url = api.ADD_TAGS_WHITELIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'post',
    data,
  });
}
export function deleteTagsWhitelist(affiliateId, id) {
  const url = api.DELETE_TAGS_WHITELIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'delete',
    params: {
      id,
    },
  });
}
export function checkoutCount(affiliateId, id) {
  const url = api.CHECK_COUNT.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'get',
    params: {
      id,
    },
  });
}

// payout setting
export function getAffOfferPayoutList(affiliateId, query) {
  const url = api.AFF_OFFER_PAYOUT_LIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'get',
    params: query,
  });
}
export function addAffOfferPayout(affiliateId, data) {
  const url = api.ADD_AFF_OFFER_PAYOUT.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'post',
    data,
  });
}
export function updateAffOfferPayout(affiliateId, data) {
  const url = api.UPDATE_AFF_OFFER_PAYOUT.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'put',
    data,
  });
}
export function deleteAffOfferPayout(affiliateId, offerId) {
  const url = api.DELETE_AFF_OFFER_PAYOUT.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'delete',
    params: { offerId },
  });
}

export function getAffSourcePayoutList(affiliateId, query) {
  const url = api.AFF_SOURCE_PAYOUT_LIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'get',
    params: query,
  });
}
export function addAffSourcePayout(affiliateId, data) {
  const url = api.ADD_AFF_SOURCE_PAYOUT.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'post',
    data,
  });
}
export function updateAffSourcePayout(affiliateId, data) {
  const url = api.UPDATE_AFF_SOURCE_PAYOUT.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'put',
    data,
  });
}
export function deleteAffSourcePayout(affiliateId, sourceId) {
  const url = api.DELETE_AFF_SOURCE_PAYOUT.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'delete',
    params: { sourceId },
  });
}

export function getAffPayoutList(affiliateId, query) {
  const url = api.AFF_PAYOUT_LIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'get',
    params: query,
  });
}
export function addAffPayout(affiliateId, percent) {
  const url = api.ADD_AFF_PAYOUT.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'post',
    data: {
      percent,
    },
  });
}
export function updateAffPayout(affiliateId, percent) {
  const url = api.UPDATE_AFF_PAYOUT.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'put',
    data: { percent },
  });
}
export function deleteAffPayout(affiliateId) {
  const url = api.DELETE_AFF_PAYOUT.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'delete',
  });
}

// adjust rate setting
export function getAdjustRateList(affiliateId, query) {
  const url = api.ADJUST_RATE_LIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'get',
    params: query,
  });
}
export function addAdjustRate(affiliateId, data) {
  const url = api.ADD_ADJUST_RATE.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'post',
    data,
  });
}
export function deleteAdjustRate(affiliateId, ids) {
  const url = api.DELETE_ADJUST_RATE.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'delete',
    params: {
      ids,
    },
  });
}
export function getTagsList() {
  return request({
    url: api.TAGS_LIST,
    method: 'get',
  });
}
// source blacklist
export function getSourceBlacklist(affiliateId, query) {
  const url = api.SOURCE_BLACKLIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'get',
    params: query,
  });
}
export function addSourceBlacklist(affiliateId, data) {
  const url = api.ADD_SOURCE_BLACKLIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'post',
    data,
  });
}
export function deleteSourceBlacklist(affiliateId, sourceIds) {
  const url = api.DELETE_SOURCE_BLACKLIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'delete',
    params: {
      sourceIds,
    },
  });
}

// prod blacklist
export function getProdBlacklist(affiliateId, query) {
  const url = api.PROD_BLACKLIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'get',
    params: query,
  });
}
export function addProdBlacklist(affiliateId, data) {
  const url = api.ADD_PROD_BLACKLIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'post',
    data,
  });
}
export function deleteProdBlacklist(affiliateId, prodIds) {
  const url = api.DELETE_PROD_BLACKLIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'delete',
    params: {
      prodIds,
    },
  });
}

// offer black
export function getOfferBlacklist(affiliateId, query) {
  const url = api.OFFER_BLACKLIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'get',
    params: query,
  });
}
export function addOfferBlacklist(affiliateId, data) {
  const url = api.ADD_OFFER_BLACKLIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'post',
    data,
  });
}
export function deleteOfferBlacklist(affiliateId, offerIds) {
  const url = api.DELETE_OFFER_BLACKLIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'delete',
    params: {
      offerIds,
    },
  });
}

// offer aff sub blacklist
export function getOfferSubBlacklist(affiliateId, query) {
  const url = api.OFFER_SUB_BLACKLIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'get',
    params: query,
  });
}
export function addOfferSubBlacklist(affiliateId, data) {
  const url = api.ADD_OFFER_SUB_BLACKLIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'post',
    data,
  });
}
export function deleteOfferSubBlacklist(affiliateId, ids) {
  const url = api.DELETE_OFFER_SUB_BLACKLIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'delete',
    params: {
      ids,
    },
  });
}

// offer aff sub
export function getSourceSubBlacklist(affiliateId, query) {
  const url = api.SOURCE_SUB_BLACKLIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'get',
    params: query,
  });
}
export function addSourceSubBlacklist(affiliateId, data) {
  const url = api.ADD_SOURCE_SUB_BLACKLIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'post',
    data,
  });
}
export function deleteSourceSubBlacklist(affiliateId, ids) {
  const url = api.DELETE_SOURCE_SUB_BLACKLIST.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'delete',
    params: {
      ids,
    },
  });
}

export function getOfferDistribution(affiliateId) {
  const url = api.AFF_OFFER_DISTRIBUTION.replace('{affiliateId}', affiliateId);
  return request({
    url,
    method: 'get',
  });
}
// FLOW TAG
export function getFlowTagList(query) {
  return request({
    url: api.FLOW_TAG_LIST,
    method: 'get',
    params: query,
  });
}
export function addFlowTag(data) {
  return request({
    url: api.ADD_FLOW_TAG,
    method: 'post',
    data,
  });
}
export function deleteFlowTag(data) {
  return request({
    url: api.DELETE_FLOW_TAG,
    method: 'post',
    data,
  });
}
export function confirmTraffic(offerIds) {
  console.log(offerIds);
  return request({
    url: api.CONFIRM_TRAFFIC,
    method: 'post',
    data: {
      offerIds,
    },
  });
}

export function exportOfferWhite(affiliateId, query) {
  const url = api.EXPORT_OFFER_WHITE_LIST.replace('{affiliateId}', affiliateId);
  // window.open(`${api.EXPORT_OFFER_WHITE_LIST}?${query}`, '_blank');
  window.open(url + '?' + query, '_blank');
  /*  return request({
    url: api.CONFIRM_TRAFFIC,
    method: 'post',
    data: {
      offerIds,
    },
  });*/
  // return url;
}
